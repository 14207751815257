/** @type {import('next-sitemap').IConfig} */
const siteUrl = process.env.SITE_URL || 'http://localhost:4200/'
module.exports = {
    siteUrl: siteUrl,
    priority: 0.7,
    sitemapSize: 1000,
    generateRobotsTxt: true,
    sourceDir: 'dist/apps/std-watch/.next',
    outDir: 'dist/apps/std-watch/public/',
    exclude: ["/common-stds", "/faq", "/layout","/medical-team", "/medical-team/*", "/reviews", "/404", "/preview/*", "/dynamic-sitemap.xml", "/us/*", "/ppc_pages/*"],
    // Default transformation function

    robotsTxtOptions: {
      additionalSitemaps: [
        `${siteUrl}blog/dynamic-sitemap.xml`
      ],
    },
  }

